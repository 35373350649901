import React, { useEffect, useState } from 'react';
import whatMattersData from './wmJsonData'
import LineChart from './LineChartWithDetails';
import SmallShield from '../../Usables/SmallSheild';

const Grid_LineCharts = ({allData,  shield_data }) => {

    const [maxValue , setMaxValue] = useState(0)
    const [selectedChart, setSelectedChart] = useState(null);

    useEffect(()=>{
        const max = Math.max(
            ...allData.map(item => item.data[0].value)
        );
        setMaxValue(max +2);
        
    },[])

    const handleChartClick = (data) => {
        setSelectedChart(data);
    };

    const handleCloseChart = () => {
        setSelectedChart(null);
    };


    return (
        <div style={{display: 'grid', gridTemplateColumns: '94% 10%'}}>
        <div style={{ height: '58vh',  textAlign: 'left' }}>
            {selectedChart ? (
                 <div style={{  display: 'grid', height: "58vh",overflowY: "hidden", gridTemplateColumns: '1fr', gap: '3px' , justifyContent: 'center', alignItems: 'center' }}>
                 <div style={{ 
                        
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        alignItems: 'center', 
                        width: '100%', 
                        paddingBottom: '5px',
                    }}>
                        <button 
                            onClick={handleCloseChart} 
                            style={{ 
                                backgroundColor: 'transparent', 
                                border: 'none', 
                                color: 'white',  // Adjust color as needed
                                fontSize: '50px',  // Adjust size as needed
                                cursor: 'pointer',
                                position: 'absolute',  // Positions the button on top of the div
                              
                            }}
                        >
                            &times;
                        </button>
                    </div>
                
                    <LineChart data={selectedChart.data} color={selectedChart.color} heading={selectedChart.heading} details={selectedChart.statements} fullChart = {true}  maxValue = {maxValue}/>
                       
                    
                </div>
            ) : (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' , height:"60vh", margin: "0", overflowY:"auto"}}>
                {allData.map((chart, index) => (
                    
                    <div key={index} onClick={() => handleChartClick(chart)}>
                        <LineChart data={chart.data} color={chart.color} heading={chart.heading} details={chart.statements} value = {chart.value} fullChart = {false}  maxValue = {maxValue}/>
                    </div>

                ))}
            </div>

            )}
        </div>
        <div style={{ 
            position: 'relative', 
            display: 'flex', 
            justifyContent: 'flex-end', // Aligns to the right
            alignItems: 'flex-end',     // Aligns to the bottom
                            
            }}>
            <SmallShield 
          scale = '80%'
          waveTxt = {shield_data.waveTxt}
          fill = {shield_data.fill}
            />
        </div>
    </div>
        
    );
};

export default Grid_LineCharts;
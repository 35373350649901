import React from 'react';
import { useLocation } from 'react-router-dom';
import ChartsComponent from './ChartsComponent';
import constants from '../Usables/Constants';
import useDataFetcher from './useDataFetcher';

const Branch = () => {
    let location = useLocation();
    const company = constants.reporting_countries[location.state.country].company;
    const sectionColor = "linear-gradient(45deg, rgba(1,0,255, 0.8) 0%, rgba(1, 0, 255, 0.8) 100%)"; 

    const section_obj = {
        name: 'Branch',
        url_part: 'branch_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(location.state.report,location.state.country, section_obj, 'Personal Banking');

    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }

    const charts = [
        ...(Object.keys(data.nps).length>0? [{
            // Line Graph
            title: company.toUpperCase() + ' BRANCH NPS',
            chart_type: 'line',
            sectionTitleColor : sectionColor,
            ...data.nps,
            icon: 'trending_up'
        }]:[]),
        ...(Object.keys(data.rdist).length>0? [{   
            // Stacked bargraph
            title: company.toUpperCase() + ' BRANCH NPS RATING DISTRIBUTION',
            chart_type: 'multi_series_stacked_bar',
            sectionTitleColor : sectionColor,
            ...data.rdist,
            icon: 'vertical_distribute'
        }]:[]),
        ...(Object.keys(data.delivery_exp).length>0? [{  
            // Bar Graph with a line
            title: 'CHANNEL DELIVERY OF EXPERIENCE',
            chart_type: 'column',
            sectionTitleColor : sectionColor,
            ...data.delivery_exp,
            icon: 'verified'
        }]:[])   
    ]
    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
            isMarket={false}
           
        />
    );
};

export default Branch;